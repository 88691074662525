import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { utils as koilibUtils } from "koilib"
import { get as _get } from "lodash";
import { BigNumber } from "bignumber.js";
import { useTheme, Box, Paper, Container, Card, CardContent, CardHeader, CircularProgress, Typography, Chip, Modal } from "@mui/material";

// constants
import { pools as ListPools } from "../../constants/earn"

// icons
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";

// components
import FiltersEarn from "../../components/FiltersEarn";
import StakingComponent from "../../components/Staking";

// Utils
import { getAddressToken } from "./../../utils/token"

// contracts
import { StakingContract, TokenContract } from "../../helpers/contracts";


const PoolsPage = () => {
  // Hooks
  const theme = useTheme();

  // components state
  const [stake, setStake] = useState(null)
  const [timer, setTimer] = useState(null)
  const [pools, setPools] = useState([])
  const [loading, setLoading] = useState(true)

  // filters state
  const [filterStake, setFilterStake] = useState(false);
  const [filterStatus, setFilterStatus] = useState("Live");
  const [filterSearch, setFilterSearch] = useState(null);

  // selectors
  const headInfo = useSelector((state) => state.blockchain.headInfo);
  const walletSelector = useSelector(state => state.wallet);
  const base_tokens = useSelector((state) => state.tokens.base_tokens);
  const custom_tokens = useSelector((state) => state.tokens.custom_tokens);

  // variables
  const provider = _get(walletSelector, "provider", null)
  const signer = _get(walletSelector, "signer", null)


  // load pools
  let loadPool = async () => {
    let result = []
    for (let index = 0; index < ListPools.length; index++) {
      let _pool = ListPools[index];

      // data
      let pool = null;

      // load pool
      let _contractStaking = StakingContract(_pool.address, provider, signer);
      try {
        pool = (await _contractStaking.functions.get_pool({})).result
      } catch (error) {
        console.log(error)
        continue;
      }

      // tokens data
      let tokenList = base_tokens.concat(custom_tokens);
      let tokenReward = tokenList.find(token => token.address === _get(pool, "tokenReward", ""));
      let tokenDeposit = tokenList.find(token => token.address === "koin")// _get(pool, "tokenDeposit", ""));
      result.push({
        ...pool,
        ..._pool,
        tokenReward,
        tokenDeposit,
        totalEarn: "0",
        balance: "0",
        totalLiquidity: "0",
      })
    }
    return result;
  }

  // load balance
  let loadBalances = async (poolbase = []) => {
    let result = [];
    for (let index = 0; index < poolbase.length; index++) {
      let _pool = poolbase[index];

      // data
      let balance = 0;

      // load pools
      let _contractStaking = StakingContract(_pool.address, provider, signer);
      try {
        balance = (await _contractStaking.functions.balance_of({ owner: signer.getAddress() })).result;
      } catch (error) {
        console.log(error)
        continue;
      }

      result.push({
        ..._pool,
        balance: balance ? balance.value : "0",
      })
    }
    return result;
  }

  // load earn in pools
  let loadEarns = async (poolsbase = []) => {
    let result = [];
    for (let index = 0; index < poolsbase.length; index++) {
      let _pool = poolsbase[index];
      let totalEarn;
      let _contractStaking = StakingContract(_pool.address, provider, signer);
      try {
        totalEarn = (await _contractStaking.functions.get_pending_reward({ owner: signer.getAddress() })).result;
      } catch (error) {
        console.log(error)
        continue;
      }
      result.push({
        ..._pool,
        totalEarn: totalEarn ? totalEarn.value : "0"
      })
    }
    return result;
  }
  
  // load pool
  let loadPools = async () => {
    try {
      let result = []
      result = await loadPool();
      result = await loadBalances(result)
      result = await loadEarns(result);
      setPools(result);
      setLoading(false);
    } catch (error) {
      console.log(error)
    }
  }

  const applyFilters = (_pools) => {
    let result = _pools

    // filter stake
    if(filterStake) {
      result = result.filter(pool => pool.balance != "0")
    }

    // filter status
    if(filterStatus == "Finished") {
      let blockHead = new BigNumber(_get(headInfo, "head_topology.height", "0"))
      result = result.filter(pool => {
        let blockEnd = new BigNumber(_get(pool, "blockEnd", "0"))
        if(blockHead.isGreaterThan(blockEnd)) {
          return pool;
        }
      })
    }


    if(filterSearch) {

    }


    return result
  }

  // effects
  useEffect(() => {
    if(base_tokens.concat(custom_tokens).length) {
      // only load when loading is false
      loadPools()
    }
  }, [ base_tokens.concat(custom_tokens).length ])

  useEffect(() => {
    let _timer = timer;
    const updateValues = async () => {
      let result = pools
      result = await loadBalances(result)
      result = await loadEarns(result);
      setPools(result);
      if(_timer) {
        clearTimeout(_timer)
        _timer = null;
      }
      _timer = setTimeout(updateValues, 6000)
      setTimer(_timer)
    }
    if(pools.length) {
      updateValues();
    }
    return () => {
      if(_timer) {
        console.log("clear timer")
        clearTimeout(_timer)
        _timer = null;
      }
    }
  }, [ loading ])

  if(loading) {
    return (
      <Card sx={{ padding: "1em", maxWidth: "400px", marginX: "auto", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
        <CardHeader
          title={"Pools"}
          sx={{ paddingBottom: "4px", textTransform: "uppercase" }}
        />
        <Box sx={{ textAlign: "center", marginTop: "20px" }}>
          <CircularProgress sx={{ marginBottom: "40px" }} />
        </Box>
        <Typography>We are loading the pools...</Typography>
      </Card>
    )
  }

  const getImageToken = (logoURI)=> {
    if(logoURI != "") {
      return (
        <img src={logoURI} style={{ width: "30px", height: "30px", borderRadius: "50%" }} alt="token" />
      )
    }
    return <HelpRoundedIcon color="primary" style={{ width: "30px", height: "30px", borderRadius: "50%" }} alt="token" />
  }

  const getStatus = (el) => {
    let blockHead = new BigNumber(_get(headInfo, "head_topology.height", "0"))
    let blockStart = new BigNumber(_get(el, "blockStart", "0"))
    let blockEnd = new BigNumber(_get(el, "blockEnd", "0"))
    if(blockHead.isLessThan(blockStart)) {
      return <Chip label="Waiting" color="info" />
    }
    if(blockHead.isGreaterThan(blockEnd)) {
      return <Chip label="Finished" color="error" />
    }
    return <Chip label="Live" color="primary" />
  }

  const DetailsPool = ({ el }) => {
    return (
      <Box onClick={() => setStake(el)} sx={{ mt: ".75em", width: "100%", height: "auto", backgroundColor: "background.light", }} borderRadius={"10px"}>
        <Box sx={{ padding: { xs: "0px", sm: "10px 10px 10px 10px" }, display: "flex", justifyContent: "space-around", "&:hover": { cursor: "pointer" }, height: "80px", maxHeight: "100px" }}>

          <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: { xs: "100%", sm: "60%", md: "60%" } }}>
            <Box sx={{ position: "relative", width: "50px", height: "44px" }}>
              <Box sx={{ position: "absolute", width: "50%", inset: "0px auto auto 0px" }}>
                { getImageToken(_get(el, "tokenDeposit.logoURI", "")) }
              </Box>
              <Box sx={{ position: "absolute", width: "82%", inset: "auto 0px 0px auto" }}>
                { getImageToken(_get(el, "tokenReward.logoURI", "")) }
              </Box>
            </Box>
            <Typography>{ _get(el, "tokenDeposit.symbol", "") }-{ _get(el, "tokenReward.symbol", "")  }</Typography>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "inline-flex", md: "inline-flex" }, alignItems: "center", width: { xs: "100%", sm: "40%", md: "20%" } }}>
            { getStatus(el) }
          </Box>
          <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, alignItems: "center", width: { xs: "100%", sm: "0%", md: "20%" } }}>
            <Typography>{koilibUtils.formatUnits(_get(el, "totalEarn", "0"), _get(el, "tokenDeposit.decimals", ""))} {_get(el, "tokenReward.symbol", "")}</Typography>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, alignItems: "center", width: { xs: "100%", sm: "0%", md: "20%" } }}>
            <Typography>{koilibUtils.formatUnits(_get(el, "balance", "0"), _get(el, "tokenDeposit.decimals", "0"))} { _get(el, "tokenDeposit.symbol", "0") }</Typography>
          </Box>
          <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, alignItems: "center", width: { xs: "100%", sm: "0%", md: "20%" } }}>
            <Typography>{koilibUtils.formatUnits(_get(el, "supplyDeposit", "0"), _get(el, "tokenDeposit.decimals", "0"))} { _get(el, "tokenDeposit.symbol", "0") }</Typography>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Container sx={{ maxWidth: "2140px" }}>
      <Box component={Paper} sx={{ padding: "20px" }}>
        <FiltersEarn
          name="Pools"
          search="Search pool..."
          enableStake={true} filterStake={filterStake} setFilterStake={setFilterStake}
          enableStatus={true} filterStatus={filterStatus} setFilterStatus={setFilterStatus}
          enableSearch={false} filterSearch={filterSearch} setFilterSearch={setFilterSearch}
        />


        <Box>
          <Box sx={{ mt: "2em", display: "flex", padding: { xs: "0px", sm: "10px 10px 10px 10px" } }}>
            <Box display={"inline-flex"} sx={{ width: { xs: "100%", sm: "60%" } }}>
              <Typography>Pool Name</Typography>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "inline-flex", md: "inline-flex" }, width: { xs: "100%", sm: "40%", md: "20%" } }}>
              <Typography>Active</Typography>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, width: { xs: "100%", sm: "0%", md: "20%" } }}>
              <Typography>Earn</Typography>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, width: { xs: "100%", sm: "0%", md: "20%" } }}>
              <Typography>Staked</Typography>
            </Box>
            <Box sx={{ display: { xs: "none", sm: "none", md: "inline-flex" }, width: { xs: "100%", sm: "0%", md: "20%" } }}>
              <Typography>Total Staked</Typography>
            </Box>
          </Box>


          {
            pools.length != 0 ?
              applyFilters(pools).map((el, i) => <DetailsPool el={el} key={i} />)
            : (
              <Card variant="outlined" sx={{ marginTop: "10px" }}>
                <CardContent sx={{ paddingBottom: "16px !important" }}>
                  <Typography variant="subtitle1" textAlign={"center"}>There are no active pools</Typography>
                </CardContent>
              </Card>
            )
          }

          <Modal
            open={!!stake}
            onClose={() => setStake(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: "flex" }}
          >
            <Box
              sx={{
                padding: "10px",
                width: "100%",
                height: "auto",
                maxWidth: "500px",
                borderRadius: "10px",
                margin: "auto auto"
              }}
            >
              <StakingComponent
                type={"pool"}
                name={ `${_get(pools[0], "tokenDeposit.symbol", "") }` }
                image={
                  <>
                    { getImageToken(_get(pools[0], "tokenDeposit.logoURI", "")) }
                  </>
                }
                onClose={() => setStake(null)}
                {...pools[0]}
              />
            </Box>
          </Modal>

        </Box>



      </Box>
    </Container>
  )
}

export default PoolsPage;