export const CHAINS_IDS =  {
  MAINNET: "EiBZK_GGVP0H_fXVAM3j6EAuz3-B-l3ejxRSewi7qIBfSA==",
  TESTNET: "EiBncD4pKRIQWco_WRqo5Q-xnXR7JuO3PtZv983mKdKHSQ==",
  UNSUPPORTED: "UNSUPPORTED",
}

export const CHAIN_NAMES = {
  MAINNET: "MAINNET",
  TESTNET: "HARBINGER"
}

export const CHAIN_IDS_TO_NAMES  =  {
  [CHAINS_IDS.MAINNET]: "Koinos Mainnet",
  [CHAINS_IDS.TESTNET]: "Koinos Testnet",
}

export const CHAIN_INFO =  {
  [CHAINS_IDS.MAINNET]: {
    docs: "",
    explorer: "",
    label: "",
    logoUrl: "",
    currency: {
      name: "Koin",
      symbol: "KOIN",
      decimals: 8
    }
  },
  [CHAINS_IDS.TESTNET]: {
    docs: "",
    explorer: "",
    label: "",
    logoUrl: "",
    currency: {
      name: "tKoin",
      symbol: "TKOIN",
      decimals: 8
    }
  },
}

