import React, { useState } from "react"
import { routes } from "../../constants/routes"
import { useNavigate } from "react-router-dom";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { BottomNavigation, BottomNavigationAction, Box, Popper, Button } from "@mui/material"


export const BottomNav = (props) => {
  const navigate = useNavigate();

  // state
  const [navIndex, setNavIndex] = useState(0)
  const [subMenu, setSubMenu] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  // sub menu
  const prepareSubRoutes = () => {
    let routeList = []
    if(!subMenu) return;
    for (let route of subMenu.childrens) {
      routeList.push(
      <Button color="primary" size={"large"} target={"_blank"} key={route.index} sx={{ width: "100%", marginY: "0.5em", color: "white", "&:hover": { color: "primary.main" } }} href={route.external ? route.route : null} onClick={() => { (route.external ? null : navigate(route.route)) }}>
        {route.label}
      </Button>)
    }
    return routeList
  }
  const prepareRoutes = () => {
    let routesList = []
    for (let route of routes) {
      if(route.type == "dropdown") {
        routesList.push(    
          <BottomNavigationAction
            key={route.index}
            onClick={(event) => {
              let _menu = route
              let _anchor = event.currentTarget
              if(subMenu && subMenu.index == route.index) {
                _menu = null;
                _anchor = null;
              }
              setSubMenu(_menu)
              setAnchorEl(_anchor);
              event.stopPropagation();
            }}
            sx={{ color: "white" }}
            label={route.label.toUpperCase()}
          />
        )
      } else {
        routesList.push(
          <BottomNavigationAction
            key={route.index}
            onClick={() => {
              if(!!subMenu) {
                setSubMenu(null)
                setAnchorEl(null);
              }
              navigate(route.route)
            }}
            sx={{ color: "white" }}
            label={route.label.toUpperCase()}
          />
        )
      }
    }
    return routesList
  }

  return (
    <Box>
      <ClickAwayListener
        onClickAway={() => {
          if(subMenu) {
            setSubMenu(null)
            setAnchorEl(null);
          }
        }}
        disableReactTree={true}
      >
        <Popper id={"sub-menu"} open={!!subMenu} anchorEl={anchorEl}>
          <Box sx={{ marginBottom: "5px", width: { xs: "150px" }, padding: "10px", borderRadius: "10px", backgroundColor: "background.paper", display: "flex", flexDirection: "column" }}>
            { prepareSubRoutes() }
          </Box>
        </Popper>
      </ClickAwayListener>

      <BottomNavigation
        showLabels
        value={navIndex}
        onChange={(event, newValue) => {
          setNavIndex(newValue);
        }}
        sx={props.sx}
      >
        { prepareRoutes() }
      </BottomNavigation>
    </Box>
  )
}