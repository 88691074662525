import { get as _get } from "lodash"
import { Request as JSONRequest } from "../helpers/request";

class LiquidityPositionController extends JSONRequest {
  constructor() {
    super({ url: import.meta.env.VITE_BACKEND_API_URL });
  }


  async getLiquidityPositionByPairId(pairId) {
    return await this.getPrepared(`/v2/liquidity/pair/${pairId}`)
  }

  async getLiquidityPositionByUserId(userId) {
    return await this.getPrepared(`/v2/liquidity/user/${userId}`)
  }

}

export let liquidityPosition = new LiquidityPositionController();
