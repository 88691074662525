import { combineReducers } from "redux";
// reducers
import modals from "./modals";
import swap from "./swap";
import wallet from "./wallet";
import dexdata from "./dexdata";
import liquidity from "./liquidity";
import settings from "./settings";
import tokens from "./tokens";
import positions from "./positions";
import blockchain from "./blockchain";

export default combineReducers({
  modals: modals,
  swap: swap,
  wallet: wallet,
  dexdata: dexdata,
  liquidity: liquidity,
  settings: settings,
  tokens: tokens,
  positions: positions,
  blockchain: blockchain
});
