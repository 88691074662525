import { get as _get } from "lodash"
import { Request as JSONRequest } from "../helpers/request";

class PairController extends JSONRequest {
  constructor() {
    super({ url: import.meta.env.VITE_BACKEND_API_URL });
  }

  async getPairsByIdWithToken(pairIds) {
    return await this.getPrepared(`/v2/pairs/byTokens`, { ids: pairIds.join(",") });
    // let result = [];
    // let message = "";
    // let success = false;
    // for (let index = 0; index < pairIds.length; index++) {
    //   let pairId = pairIds[index];
    //   try {
    //     let _pair = await this.getPrepared(`/v2/pairs/byId`, { id: pairId });
    //     message = _get(_pair, "message", "")
    //     success = _get(_pair, "success", "")
    //     result.push(_get(_pair, "result", ""));
    //   } catch (e) {
    //     console.log(e);
    //     message = _get(_pair, "message", "")
    //     success = _get(_pair, "success", "")
    //     break;
    //   }
    // }
    // return {
    //   result: result,
    //   message: message,
    //   success: success
    // }
  }
}


export let pair = new PairController();
