import { SET_HEAD_INFO } from "../constants"

// Constants
const initialState = {
  headInfo: null,
}

// Reducer
export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case SET_HEAD_INFO:
      return { ...state, headInfo: action.payload };
    default:
      return state
  }
}
