import React, { useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";

// components
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import SearchIcon from '@mui/icons-material/Search';
import { Search, SearchIconWrapper, StyledInputBase } from "../Search"

// icons
import TuneIcon from '@mui/icons-material/Tune';


const FiltersEarn = (props) => {

  const [modalOpen, setModalOpen] = useState(false);

  // MediaQuery
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
      <Typography variant="h4">{props.name}</Typography>

      {
        !matches ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {
              props.enableStake ? (
                <Box
                  sx={{
                    marginX: 1,
                    bgcolor: 'background.light',
                    borderRadius: "10px",
                    display: "flex",
                    alignItems: "center"
                  }}
                  onClick={() => props.setFilterStake(!props.filterStake)}
                >
                  <Switch checked={props.filterStake} />
                  <Typography sx={{ marginRight: 1, color: "white" }} variant="span">Staked Only</Typography>
                </Box>
              ) : null
            }
            {
              props.enableStatus ? (
                <ButtonGroup>
                  <Button
                    variant={ props.filterStatus == "Live" ? "contained" : "outlined" }
                    size="small"
                    onClick={() => props.setFilterStatus("Live")}
                  >
                    Live
                  </Button>
                  <Button
                    variant={ props.filterStatus == "Finished" ? "contained" : "outlined" }
                    size="small"
                    onClick={() => props.setFilterStatus("Finished")}
                  >
                    Finished
                  </Button>
                </ButtonGroup>
              ) : null
            }
            {
              props.enableSearch ? (
                <Search size="small" sx={{ display: props.enableSearch ? "flex" : "hidden" }}>
                  <SearchIconWrapper>
                    <SearchIcon />
                  </SearchIconWrapper>
                  <StyledInputBase onChange={(e) => props.setFilterSearch(e.target.value)} size="small" placeholder={props.search} />
                </Search>
              ) : null
            }
          </Box>
        ) : (
          <Box>
            <TuneIcon
              sx={{ color: "white" }}
              onClick={() => setModalOpen(true)}
            />
            <Modal
              open={modalOpen}
              onClose={() => setModalOpen(false)}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  width: 400,
                  bgcolor: 'background.paper',
                  borderRadius: "10px",
                  boxShadow: 24,
                  padding: 4
                }}
              >

              </Box>
            </Modal>
          </Box>
        )
      }

    </Box>
  )
}

export default FiltersEarn;