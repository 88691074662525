import { CHAIN_NAMES } from "./chains"

const _farms = {
  [CHAIN_NAMES.MAINNET]: [
  ],
  [CHAIN_NAMES.TESTNET]: [
    // {
    //   address: "18D2BcRH3fiKLvtkXrgJsyYeYiJnKZTt4k",
    //   tokenA: "koin",
    //   tokenB: "vhp",
    //   metadata: {
    //     community: false
    //   },
    // }
  ]
}

const _pools = {
  [CHAIN_NAMES.MAINNET]: [
  ],
  [CHAIN_NAMES.TESTNET]: [
    // {
    //   address: "18D2BcRH3fiKLvtkXrgJsyYeYiJnKZTt4k",
    //   metadata: {
    //     community: false
    //   },
    // }
  ]
}

// exports
const APP_CHAIN = import.meta.env.VITE_CHAIN;
export const farms = _farms[APP_CHAIN];
export const pools = _pools[APP_CHAIN];
