import { get as _get } from "lodash";
import { BigNumber } from "bignumber.js";
import { utils as koilibUtils } from "koilib";

const getQuote = (amount, reserveA, reserveB) => {
  return new BigNumber(amount).times(reserveB).div(reserveA);
}

const getAmountOut = (amountIn, reserveIn, reserveOut, enableFees = true) => {
  const amountInWithFee = new BigNumber(amountIn).times(enableFees ? 9975 : 10000);
  const numerator = new BigNumber(amountInWithFee).times(reserveOut);
  const denominator = new BigNumber(reserveIn).times(10000).plus(amountInWithFee);
  return numerator.div(denominator);
}

const getAmountIn = (amountOut, reserveIn, reserveOut, enableFees = true) => {
  const numerator = new BigNumber(reserveIn).times(amountOut).times(10000);
  const denominator = new BigNumber(reserveOut).minus(amountOut).times(enableFees ? 9975 : 10000);
  return numerator.div(denominator).plus(1);
}

export const orderTokens = (tokenA, tokenB) => {
  let result = { token0: null, token1: null }
  if (tokenA.address > tokenB.address) {
    result.token0 = tokenA;
    result.token1 = tokenB;
  } else {
    result.token0 = tokenB;
    result.token1 = tokenA;
  }
  return result
}

export const calculateAmount = (position, typed, format = true) => {
  let result = "";
  let shares = _get(position, "shares", "");
  let totalShares = _get(position, "totalSupply", "");
  let sharePercent = new BigNumber(100).multipliedBy(shares).dividedBy(totalShares);
  if (typed === "A") {
    let reserveA = _get(position, "reserves.reserveA", "")
    let decimals = _get(position, "tokenA.decimals", "0");
    result = sharePercent.multipliedBy(reserveA).dividedBy(100).toFixed(0, 1);
    if (format) {
      result = decimals != "0" ? koilibUtils.formatUnits(result, decimals) : result;
    }
  } else {
    let reserveB = _get(position, "reserves.reserveB", "")
    let decimals = _get(position, "tokenB.decimals", "0");
    result = sharePercent.multipliedBy(reserveB).dividedBy(100).toFixed(0, 1);
    if (format) {
      result = decimals != "0" ? koilibUtils.formatUnits(result, decimals) : result;
    }
  }
  return result;
}

export const calculatePercent = (position) => {
  let shares = _get(position, "shares", "");
  let totalShares = _get(position, "totalSupply", "");
  return new BigNumber(100).multipliedBy(shares).dividedBy(totalShares).toFixed(2, 0).toString()
}

export const calculateAmountsIn = ({ amountIn = "", reserves = {}, paths = [] }) => {
  let results = {
    fees: [],
    amounts: [],
    impacts: [],
    fee: 0,
    result: 0,
    impact: 0,
  }
  let _fees = new Array(paths.length);
  let _impacts = new Array(paths.length);
  let _amounts = new Array(paths.length);
  _fees[0] = amountIn;
  _amounts[0] = amountIn;
  _impacts[0] = "0";
  for (let i = 0; i < paths.length - 1; i++) {
    let token0 = paths[i];
    let token1 = paths[i + 1];
    let _orderTokens = orderTokens(token0, token1);
    // find reserve
    let combineA = `${token0.address}/${token1.address}`;
    let combineB = `${token1.address}/${token0.address}`;
    let _reserve
    // eslint-disable-next-line no-prototype-builtins
    if (reserves.hasOwnProperty(combineA)) {
      _reserve = reserves[combineA];
    }
    else {
      _reserve = reserves[combineB]
    }

    // check if have reserve pair
    if (_reserve.reserveA === "0" || _reserve.reserveB === "0") {
      throw "NotRoute"
    }
    let FeesAjusted;
    let TokenAmountAjusted;
    let PriceImpactAjusted;
    if (paths[i].address === _orderTokens.token0.address) {
      FeesAjusted = getAmountOut(_fees[i], _reserve.reserveA, _reserve.reserveB, false)
      TokenAmountAjusted = getAmountOut(_amounts[i], _reserve.reserveA, _reserve.reserveB, true)
      if (TokenAmountAjusted.isLessThanOrEqualTo(0)) {
        throw "ExceedSwap"
      }

      // price impact
      let marketPrice = new BigNumber(_reserve.reserveA).dividedBy(_reserve.reserveB);
      let pricePaid = new BigNumber(_amounts[i]).dividedBy(TokenAmountAjusted);
      PriceImpactAjusted = new BigNumber(1).minus(new BigNumber(marketPrice).dividedBy(pricePaid));
    } else {
      FeesAjusted = getAmountOut(_fees[i], _reserve.reserveB, _reserve.reserveA, false)
      TokenAmountAjusted = getAmountOut(_amounts[i], _reserve.reserveB, _reserve.reserveA, true)
      if (TokenAmountAjusted.isLessThanOrEqualTo(0)) {
        throw "ExceedSwap"
      }

      // price impact
      let marketPrice = new BigNumber(_reserve.reserveB).dividedBy(_reserve.reserveA);
      let pricePaid = new BigNumber(_amounts[i]).dividedBy(TokenAmountAjusted);
      PriceImpactAjusted = new BigNumber(1).minus(new BigNumber(marketPrice).dividedBy(pricePaid));
    }
    if (!TokenAmountAjusted.isFinite()) {
      throw "Finite"
    }

    if (new BigNumber(_amounts[i]).isEqualTo(1)) {
      throw "ExededInflow"
    }

    _fees[i + 1] = FeesAjusted.toFixed(0).toString();
    _amounts[i + 1] = TokenAmountAjusted.toFixed(0).toString();
    _impacts[i + 1] = PriceImpactAjusted.toFixed(6).toString();
  }

  // set results
  results["fees"] = _fees;
  results["amounts"] = _amounts;
  results["impacts"] = _impacts;
  results["fee"] = _fees[_impacts.length - 1];
  results["result"] = _amounts[_amounts.length - 1];
  results["impact"] = _impacts[_impacts.length - 1];
  return results;
}

export const calculateAmountsOut = ({ amountOut = "", reserves = {}, paths = [] }) => {
  let results = {
    fees: [],
    amounts: [],
    impacts: [],
    fee: 0,
    result: 0,
    impact: 0,
  }
  let _fees = new Array(paths.length);
  let _impacts = new Array(paths.length);
  let _amounts = new Array(paths.length);
  _fees[_amounts.length - 1] = amountOut;
  _amounts[_amounts.length - 1] = amountOut;
  _impacts[_amounts.length - 1] = "0";
  for (let i = paths.length - 1; i > 0; i--) {
    let token0 = paths[i - 1];
    let token1 = paths[i]
    let _orderTokens = orderTokens(token0, token1);
    // find reserve
    let combineA = `${token0.address}/${token1.address}`;
    let combineB = `${token1.address}/${token0.address}`;
    let _reserve
    // eslint-disable-next-line no-prototype-builtins
    if (reserves.hasOwnProperty(combineA)) {
      _reserve = reserves[combineA];
    }
    else {
      _reserve = reserves[combineB]
    }

    if (_reserve.reserveA === "0" || _reserve.reserveB === 0) {
      throw "NotRoute"
    }

    let FeesAjusted;
    let TokenAmountAjusted;
    let PriceImpactAjusted;
    if (paths[i - 1].address === _orderTokens.token0.address) {
      FeesAjusted = getAmountIn(_amounts[i], _reserve.reserveA, _reserve.reserveB, false)
      TokenAmountAjusted = getAmountIn(_amounts[i], _reserve.reserveA, _reserve.reserveB, true)
      if (TokenAmountAjusted.isLessThanOrEqualTo(0)) {
        throw "ExceedSwap"
      }

      // price impact
      let marketPrice = new BigNumber(_reserve.reserveA).dividedBy(_reserve.reserveB);
      let pricePaid = new BigNumber(TokenAmountAjusted).dividedBy(_amounts[i]);
      PriceImpactAjusted = new BigNumber(1).minus(new BigNumber(marketPrice).dividedBy(pricePaid));

    } else {
      FeesAjusted = getAmountIn(_amounts[i], _reserve.reserveB, _reserve.reserveA, false)
      TokenAmountAjusted = getAmountIn(_amounts[i], _reserve.reserveB, _reserve.reserveA, true)
      if (TokenAmountAjusted.isLessThanOrEqualTo(0)) {
        throw "ExceedSwap"
      }

      // price impact
      let marketPrice = new BigNumber(_reserve.reserveB).dividedBy(_reserve.reserveA);
      let pricePaid = new BigNumber(TokenAmountAjusted).dividedBy(_amounts[i]);
      PriceImpactAjusted = new BigNumber(1).minus(new BigNumber(marketPrice).dividedBy(pricePaid));
    }
    if (!TokenAmountAjusted.isFinite()) {
      throw "Finite"
    }

    if (new BigNumber(_amounts[i]).isEqualTo(1)) {
      throw "ExededInflow"
    }

    _fees[i - 1] = FeesAjusted.toFixed(0).toString();
    _amounts[i - 1] = TokenAmountAjusted.toFixed(0).toString();
    _impacts[i - 1] = PriceImpactAjusted.toFixed(6).toString();
  }

  // set results
  results["fees"] = _fees;
  results["amounts"] = _amounts;
  results["impacts"] = _impacts;
  results["fee"] = _fees[0];
  results["result"] = _amounts[0];
  results["impact"] = _impacts[0];
  return results;
}

export const calculateQuotes = ({ reserves = {}, amount = "", assets = [], baseAsset = {} }) => {
  let results = {
    result: 0
  }
  if (new BigNumber(amount).isLessThanOrEqualTo(0)) {
    throw "InsufficientLiquidity"
  }
  let { token0 } = orderTokens(assets[0], assets[1]);
  let reserveA
  let reserveB
  // order reserves
  if (token0.address == baseAsset.address) {
    reserveA = reserves.reserveA;
    reserveB = reserves.reserveB;
  } else {
    reserveA = reserves.reserveB;
    reserveB = reserves.reserveA;
  }
  // get quote a
  let quote = getQuote(amount, reserveA, reserveB)
  results["result"] = quote.toFixed(0).toString();
  return results;
}

/**
 * Calculates default mana value based on account_rc and returns a value as a String
 * @param {BigInt} account_rc 
 * @returns String
 */
export const calculateDefaultMana = (account_rc, limitbase) => {
  // it was decided to always use 4 mana for all transactions
  if(new BigNumber(account_rc).isLessThan(limitbase ? limitbase : 100000000)) {
    return account_rc
  }  
  return new BigNumber(limitbase ? limitbase : 100000000).toString();
  // let _100 = new BigNumber(10000000000);
  // let _1000 = new BigNumber(100000000000);
  // let account_rc = new BigNumber(_account_rc)
  // // 100 KOIN or less use 90% of mana account
  // if (account_rc.isLessThan(_100)) {
  //   return new BigNumber(10).multipliedBy(account_rc).dividedBy(100).toString()
  // }
  // if (account_rc.isGreaterThan(_100) && _100.isLessThan(_1000)) {
  //   return new BigNumber(5).multipliedBy(account_rc).dividedBy(100).toString()
  // }
  // if (account_rc.isGreaterThanOrEqualTo(_1000)) {
  //   return new BigNumber(2).multipliedBy(account_rc).dividedBy(100).toString()
  // }
}
