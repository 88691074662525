import { get as _get } from "lodash";
import { SET_TRADING_VOLUME_DATA, SET_CAPITAL_LOCKED_DATA, SET_BLOCK_HEIGHT } from "../constants"
import { global } from "../../services/GlobalController";

// Constants
const initialState = {
  tradingVolume: "-",
  capitalLocked: "-",
  blockheight: null
}
try {
  let result = await global.getBlockHeight()
  initialState.blockheight = _get(result, "head_block", null)
} catch (e) {
  console.log(e)
}

// Reducer
export default function chatReducer(state = initialState, action) {
  switch (action.type) {
    case SET_TRADING_VOLUME_DATA:
      return { ...state, tradingVolume: action.payload };
    case SET_BLOCK_HEIGHT:
      return { ...state, blockheight: action.payload };
    case SET_CAPITAL_LOCKED_DATA:
      return { ...state, capitalLocked: action.payload }
    default:
      return state
  }
}
