import { get as _get } from "lodash"
import { CONFIG_BASE } from "./../constants/configs"
import { TokenContract, NameServiceContract } from "./../helpers/contracts"

export const loadTokenData = async (address) => {
  const contract = await TokenContract(address)
  if (contract) {
    let token = {
      decimals: await contract.functions.decimals(),
      name: await contract.functions.name(),
      symbol: await contract.functions.symbol(),
      address: address,
    }
    token.decimals = _get(token, "decimals.result.value", 0)
    token.name = token.name.result.value
    token.symbol = token.symbol.result.value
    token.logoURI = ""
    return token;
  }
}

export const getAddressToken = async (address) => {
  let _address = address;
  if(_get(CONFIG_BASE, 'chain.nameservice', []).indexOf(_address) != -1 && _get(CONFIG_BASE, 'chain.contracts.nameservice', "") != "") {
    let ns = NameServiceContract()
    let result = await ns.functions.get_address({ name: _address });
    _address = _get(result, 'result.value.address', '')
  }
  return _address
}

export const maxDecimal = (amount, decimals) => {
  let dc = amount.split(".");
  if (Array.isArray(dc) && dc.length >= 2) {
    return dc[0] + "." + dc[1].substring(0, decimals)
  }
  return amount;
}