/* eslint-disable */
import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get as _get, isEmpty as _isEmpty } from "lodash";
import { utils as koilibUtils, Contract, Signer } from "koilib";
import { BigNumber } from "bignumber.js";
import { useSnackbar } from "notistack";
import { Buffer } from 'buffer';

// utils
import { HDKoinos } from "./../../utils/HDKoinos"
import { waitTransation } from "./../../utils/transactions"

// components mui
import { CardHeader, Box, Tooltip, Button, Card, CardContent, CardActions, Typography, IconButton, Divider, CircularProgress } from "@mui/material";

// icons
import SettingsIcon from "@mui/icons-material/Settings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import HistoryIcon from "@mui/icons-material/History";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";

// components
import TokenInputPanel from "../../components/TokenInputPanel";
import Maintenance from "../../components/Maintenance";

// constants
import { CONFIG_BASE } from "./../../constants/configs"

// Actions
import { setModalData, setModal } from "../../redux/actions/modals";
import { setAssetOne, setAssetTwo } from "../../redux/actions/liquidity";
// helpers
import { calculateDefaultMana, calculateQuotes, orderTokens } from "./../../helpers/calculate";

// contracts intances
import { PeripheryContract, CoreContract, TokenContract, NameServiceContract } from "../../helpers/contracts";

const AddLiquidityPage = () => {
  // Dispatch to call actions
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const Snackbar = useSnackbar();

  // selectors
  const networkSelector = useSelector(state => state.settings.network);
  const liquiditySelector = useSelector((state) => state.liquidity);
  const walletSelector = useSelector((state) => state.wallet);
  const settingsSelector = useSelector(state => state.settings);

  // references
  const t_reserve = useRef(null); // timer get reserves

  // state
  const [inputAssetOne, setInputassetOne] = useState("");
  const [inputAssetTwo, setInputAssetTwo] = useState("");
  const [balanceAssetOne, setBalanceAssetOne] = useState("loading");
  const [balanceAssetTwo, setBalanceAssetTwo] = useState("loading");
  const [debounceOne, setDebounceOne] = useState(null);
  const [debounceTwo, setDebounceTwo] = useState(null);

  const [loading, setLoading] = useState(false);
  const [poolAddress, setPoolAddress] = useState(null);
  const [reserves, setReserves] = useState({});
  const [positionErrors, setPositionErrors] = useState(null);

  // variables
  const assetOne = _get(liquiditySelector, "assetOne", null);
  const assetTwo = _get(liquiditySelector, "assetTwo", null);

  const slippage = _get(settingsSelector, "slippage", "0.1");
  const provider = _get(walletSelector, "provider", null)
  const signer = _get(walletSelector, "signer", null)

  // contracts
  const refreshTiming = 30 * 1000;
  const debounceTiming = 800;
  const ContractAssetOne = assetOne ? TokenContract(_get(assetOne, "address", ""), provider, signer) : null;
  const ContractAssetTwo = assetTwo ? TokenContract(_get(assetTwo, "address", ""), provider, signer) : null;

  const openModalSettingsApp = (data) => {
    dispatch(setModalData(data));
    dispatch(setModal("SettingsApp"));
  };
  const openModalSelectToken = (data) => {
    dispatch(setModalData(data));
    dispatch(setModal("SelectToken"));
  };
  const getReserves = () => {
    // clean current status of reserves
    if (poolAddress) {
      setPoolAddress(null);
      setReserves({})
    }

    // get new status of reserves
    if (t_reserve.current) clearTimeout(t_reserve.current);
    const _getReserves = async () => {
      const ContractPeriphery = PeripheryContract(provider, signer);
      let functions = ContractPeriphery.functions
      if (assetOne && assetTwo) {
        try {
          let pairAddress = (await functions.get_pair({ tokenA: assetOne.address, tokenB: assetTwo.address })).result
          if (_isEmpty(pairAddress)) {
            setPoolAddress(null);
            return setReserves({})
          }
          let pair = CoreContract(pairAddress.value, provider, signer);
          let supply = (await pair.functions.total_supply()).result;
          let reserves = (await pair.functions.get_reserves()).result;
          setPoolAddress(pairAddress.value);
          setReserves({
            ...reserves,
            totalSupply: _get(supply, "value", "0"),
          });
          // set refresh route and reserves
          let _refresh = () => _getReserves();
          t_reserve.current = setTimeout(_refresh, refreshTiming);
        } catch (error) {
          console.log(error)
        }
      }
    }
    _getReserves();
  }
  const changeTokens = () => {
    setInputassetOne("")
    setInputAssetTwo("")
  }
  const debounceFnOne = (value) => {
    setInputassetOne(value);
    setLoading(true);
    if (_get(reserves, "totalSupply", "0") == 0) {
      setLoading(false);
      return;
    }
    if (debounceOne) clearTimeout(debounceOne);
    let _submit = () => amountsOne(value);
    let fncDelayed = setTimeout(_submit, debounceTiming);
    setDebounceOne(fncDelayed);
  };
  const debounceFnTwo = (value) => {
    setInputAssetTwo(value);
    setLoading(true);
    if (_get(reserves, "totalSupply", "0") == 0) {
      setLoading(false);
      return;
    }
    if (debounceTwo) clearTimeout(debounceTwo);
    let _submit = () => amountsTwo(value);
    let fncDelayed = setTimeout(_submit, debounceTiming);
    setDebounceTwo(fncDelayed);
  };
  const amountsOne = async (amount) => {
    let _amountOne = koilibUtils.parseUnits(amount, assetOne.decimals);
    setPositionErrors(null);
    if (assetTwo && assetOne) {
      try {
        let { result } = await calculateQuotes({
          reserves: reserves,
          amount: _amountOne,
          assets: [assetOne, assetTwo],
          baseAsset: assetOne
        })
        let _amountF = assetTwo.decimals != "0" ? koilibUtils.formatUnits(result, assetTwo.decimals) : result
        setInputAssetTwo(_amountF)
      } catch (error) {
        console.log(error)
        setPositionErrors(error);
        setInputAssetTwo("")
      }
    }
    setLoading(false);
  };
  const amountsTwo = async (amount) => {
    let _amountTwo = koilibUtils.parseUnits(amount, assetTwo.decimals);
    setPositionErrors(null);
    if (assetOne && assetTwo) {
      try {
        let { result } = await calculateQuotes({
          reserves: reserves,
          amount: _amountTwo,
          assets: [assetOne, assetTwo],
          baseAsset: assetTwo
        })
        let _amountF = assetOne.decimals != "0" ? koilibUtils.formatUnits(result, assetOne.decimals) : result
        setInputassetOne(_amountF)
      } catch (error) {
        console.log(error)
        setPositionErrors(error);
        setInputassetOne("")
      }
    }
    setLoading(false);
  };
  const slippageAmounts = (amount, slippage, token) => {
    let _amount = koilibUtils.parseUnits(amount, token.decimals)
    let result = new BigNumber(_amount).times(new BigNumber(slippage).div(100)).minus(_amount).times(-1).toFixed(0, 1);
    return token.decimals != "0" ? koilibUtils.formatUnits(result, token.decimals) : result;
  }
  const approvalAllowance = async (tokenInfo, ownerAddress, spenderAddress, amount, configs) => {
    if (_get(tokenInfo, "allowance", true)) {
      let address_token = _get(tokenInfo, 'address', '');
      if(_get(CONFIG_BASE, 'chain.nameservice', []).indexOf(address_token) != -1 && _get(CONFIG_BASE, 'chain.contracts.nameservice', "") != "") {
        let ns = NameServiceContract(provider, signer)
        let result = await ns.functions.get_address({ name: address_token });
        address_token = _get(result, 'result.value.address', '')
      }
      let _token = TokenContract(address_token, provider, signer);
      _token.options.onlyOperation = true;
      const approvalParams = {
        owner: ownerAddress,
        spender: spenderAddress,
        value: amount
      }
      let { operation: approveSales } = await _token.functions.approve(approvalParams, configs)
      return approveSales;
    }
  }
  const getConfigs = async (_walletAddress) => {
    let configs = {
      payer: _walletAddress,
    };
    try {
      let account_rc = await provider.getAccountRc(_walletAddress);
      let rc_limit = calculateDefaultMana(account_rc, 1500000000)
      configs.rcLimit = rc_limit;
    } catch (error) {
      console.log(error)
      setLoading(false)
      Snackbar.enqueueSnackbar("Error in mana calculation", { variant: "error" });
      return;
    }
    return configs;
  }
  const createPool = async () => {
    setLoading(true)
    // config contract
    let ContractPeriphery = PeripheryContract(provider, signer);
    ContractPeriphery.options.onlyOperation = true;
    // get functions
    let functions = ContractPeriphery.functions;
    let walletAddress = signer.getAddress();
    // load configs
    let configs = await getConfigs(walletAddress);
    // load wasm
    let CoreAbiFile = await fetch('/contracts/core.abi');
    let CoreWasmFile = await fetch(`/contracts/contract.wasm`);
    let CoreAbi = await CoreAbiFile.text();
    let CoreWasm = await CoreWasmFile.arrayBuffer();
    let bytes = Buffer.from(CoreWasm)

    let accountContract = Signer.fromSeed(HDKoinos.randomMnemonic())
    accountContract.provider = provider;
    let core = new Contract({
      signer: accountContract,
      provider: provider,
      bytecode: bytes
    });
    let transaction = null;
    let receipt = null;
    try {
      let pair = {
        tokenA: _get(assetOne, 'address', ''),
        tokenB: _get(assetTwo, 'address', ''),
      }
      let { operation: createPairOperation } = await functions.create_pair(pair, { ...configs, sendTransaction: false })
      let { transaction: _transaction, receipt: _receipt } = await core.deploy({
        abi: CoreAbi,
        authorizesCallContract: true,
        authorizesTransactionApplication: true,
        authorizesUploadContract: true,
        payer: signer.getAddress(),
        sendTransaction: true,
        nextOperations: [createPairOperation],
        beforeSend: async (tx) => {
          await signer.signTransaction(tx);
        }
      })
      transaction = _transaction
      receipt = _receipt
      Snackbar.enqueueSnackbar("Transaction sent", { variant: "info" });
    } catch (error) {
      console.log(error)
      Snackbar.enqueueSnackbar("Error sending transaction", { variant: "error" });
      setLoading(false);
      return;
    }
    // wait to be mined
    await waitTransation(provider, transaction)
    setPoolAddress(accountContract.getAddress());
    setLoading(false);
    Snackbar.enqueueSnackbar("Pool created", { variant: "success" });
  }
  const finalAddLiquidity = async () => {
    setLoading(true);
    // config contract
    let ContractPeriphery = PeripheryContract(provider, signer);
    ContractPeriphery.options.onlyOperation = true;
    // get functions
    let functions = ContractPeriphery.functions;
    let walletAddress = signer.getAddress();
    // load configs
    let configs = await getConfigs(walletAddress);
    configs.rc_limit = configs.rcLimit;
    // params
    let _amountA = koilibUtils.parseUnits(inputAssetOne, assetOne.decimals)
    let _amountAMin = new BigNumber(_amountA).times(new BigNumber(slippage).div(100)).minus(_amountA).times(-1).toFixed(0, 1)
    let _amountB = koilibUtils.parseUnits(inputAssetTwo, assetTwo.decimals)
    let _amountBMin = new BigNumber(_amountB).times(new BigNumber(slippage).div(100)).minus(_amountB).times(-1).toFixed(0, 1)
    let operations = [];
    // add allowance token a
    try {
      let approvalTokenA = await approvalAllowance(assetOne, walletAddress, ContractPeriphery.getId(), _amountA, configs);
      if (approvalTokenA) {
        operations.push(approvalTokenA)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      Snackbar.enqueueSnackbar("Approval token a error", { variant: "error" });
      return;
    }

    // add allowance token B
    try {
      let approvalTokenB = await approvalAllowance(assetTwo, walletAddress, ContractPeriphery.getId(), _amountB, configs)
      if (approvalTokenB) {
        operations.push(approvalTokenB)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      Snackbar.enqueueSnackbar("Approval token b error", { variant: "error" });
      return;
    }

    // add liquidity
    try {
      let params = {
        from: walletAddress,
        receiver: walletAddress,
        tokenA: _get(assetOne, 'address', ''),
        tokenB: _get(assetTwo, 'address', ''),
        amountADesired: _amountA,
        amountBDesired: _amountB,
        amountAMin: _amountAMin,
        amountBMin: _amountBMin
      };
      let { operation: addLiquidityOperation } = await functions.add_liquidity(params, configs);
      operations.push(addLiquidityOperation)
    } catch (error) {
      console.log(error)
      Snackbar.enqueueSnackbar("Error in add liquidity", { variant: "error" });
      return;
    }

    let transaction = null;
    let receipt = null;
    try {
      const tx = await signer.prepareTransaction({ operations: operations, header: configs });
      const { transaction: _transaction, receipt: _receipt } = await signer.sendTransaction(tx);
      transaction = _transaction
      receipt = _receipt
      Snackbar.enqueueSnackbar("Transaction sent", { variant: "info" });
    } catch (error) {
      console.log(error)
      Snackbar.enqueueSnackbar("Error sending transaction", { variant: "error" });
      setLoading(false);
      return;
    }
    // wait to be mined
    await waitTransation(provider, transaction)
    setLoading(false);
    Snackbar.enqueueSnackbar("Added liquidity", { variant: "success" });
    setTimeout(() => navigate("/liquidity"), 2000)
  }
  const loadIinit = () => {
    return () => {
      dispatch(setAssetOne(null));
      dispatch(setAssetTwo(null));
      if (t_reserve.current) clearTimeout(t_reserve.current);
    }
  }
  // functions component
  const disabledButton = () => {
    if (!assetOne || !assetTwo) return true;
    if (positionErrors) return true;
    if (loading) return true;
    if (new BigNumber(inputAssetOne).isNaN() || new BigNumber(inputAssetTwo).isNaN()) return true;
    let _inputAssetOne = koilibUtils.parseUnits(inputAssetOne, assetOne.decimals)
    let _inputAssetTwo = koilibUtils.parseUnits(inputAssetTwo, assetTwo.decimals)
    if (new BigNumber(_inputAssetOne).isGreaterThan(new BigNumber(balanceAssetOne))) return true;
    if (new BigNumber(_inputAssetTwo).isGreaterThan(new BigNumber(balanceAssetTwo))) return true;
    if (!inputAssetOne || !inputAssetTwo) return true;
    return false;
  }
  const messageButton = () => {
    if (!assetOne || !assetTwo) return "Select tokens";
    if (new BigNumber(inputAssetOne).isNaN() || new BigNumber(inputAssetTwo).isNaN()) return "Enter amounts";
    if (loading) return "Loading";
    let _inputAssetOne = koilibUtils.parseUnits(inputAssetOne, assetOne.decimals)
    let _inputAssetTwo = koilibUtils.parseUnits(inputAssetTwo, assetTwo.decimals)
    if (new BigNumber(_inputAssetOne).isGreaterThan(balanceAssetOne)) return `insufficient ${_get(assetOne, "symbol", "")} balance`;
    if (new BigNumber(_inputAssetTwo).isGreaterThan(balanceAssetTwo)) return `insufficient ${_get(assetTwo, "symbol", "")} balance`;
    if (poolAddress == null) return "Create pool";
    if (_isEmpty(reserves)) return "Add initial liquidity";
    return "Add position";
  }

  //efects
  useEffect(getReserves, [assetOne, assetTwo])
  useEffect(changeTokens, [assetOne, assetTwo])
  useEffect(loadIinit, [])

  if (!_get(CONFIG_BASE, "contracts.periphery.launched", false)) {
    return <Maintenance />
  }

  return (
    <Box sx={{ width: "100%", justifyContent: "center", display: "flex", alignItems: "center", paddingTop: "15px" }}>
      <Card variant="outlined" sx={{ maxWidth: "500px", width: "100%", marginX: "auto", borderRadius: "10px", padding: { xs: "0px", sm: "15px 20px" } }}>
        <CardActions>
          <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <IconButton aria-label="back" onClick={() => navigate("/liquidity")}>
                <ArrowBackIcon color="secondary" />
              </IconButton>
               <CardHeader
                title="ADD LIQUIDITY"
              />
            </Box>
            <Box>
              <Tooltip title={"Learn more about liquidity"}>
                <IconButton aria-label="help" component={"a"} href="https://docs.koindx.com/Protocol/liquidity" target="_blank">
                  <HelpRoundedIcon color="secondary" />
                </IconButton>
              </Tooltip>
              <IconButton aria-label="refresh" disabled={!assetOne || !assetTwo} onClick={() => getReserves()}>
                <HistoryIcon color="secondary" />
              </IconButton>
              <IconButton aria-label="settings" onClick={() => openModalSettingsApp()}>
                <SettingsIcon color="secondary" />
              </IconButton>
            </Box>
          </Box>
        </CardActions>
        <CardContent>
          <TokenInputPanel
            token={assetOne}
            inputVal={inputAssetOne}
            contract={ContractAssetOne}
            onSelect={() => openModalSelectToken({ reduce: "liquidity", value: "assetOne" })}
            onBalance={(balance) => setBalanceAssetOne(balance)}
            onInput={(amount) => debounceFnOne(amount)}
          />
          <Box sx={{ marginY: "17px", justifyContent: "center", display: "flex" }}>
            <AddIcon sx={{ fontSize: 25 }} color="secondary" />
          </Box>
          <TokenInputPanel
            token={assetTwo}
            inputVal={inputAssetTwo}
            contract={ContractAssetTwo}
            onSelect={() => openModalSelectToken({ reduce: "liquidity", value: "assetTwo" })}
            onBalance={(balance) => setBalanceAssetTwo(balance)}
            onInput={(amount) => debounceFnTwo(amount)}
          />

          {!_get(walletSelector, "wallet", null) ? (
            <Button variant="contained" sx={{ width: "100%" }} onClick={() => dispatch(setModal("Connect"))}>
              Connect
            </Button>
          ) : null}

          {_get(walletSelector, "wallet", null) ? (
            <Button
              variant="contained"
              sx={{ width: "100%" }}
              disabled={disabledButton()}
              onClick={() => poolAddress ? finalAddLiquidity() : createPool()}
            >
              {loading ? <CircularProgress color="background" size={20} sx={{ marginRight: "10px" }} /> : null}
              {messageButton()}
            </Button>
          ) : null}


          {
            assetOne && assetTwo && inputAssetOne && inputAssetTwo ?
              <Box>
                <Divider sx={{ marginY: "10px" }} />
                <Box>
                  <Box sx={{ justifyContent: "space-between", display: "flex", }}>
                    <Tooltip placement="top" title="Slippage - the difference between the expected price of an asset and the actual price at which the trade is executed.">
                      <Typography variant='caption'>Slippage</Typography>
                    </Tooltip>
                    <Typography variant='caption'>{slippage}%</Typography>
                  </Box>
                  <Box sx={{ justifyContent: "space-between", display: "flex", }}>
                    <Typography variant='caption'>Min {_get(assetOne, "symbol", "")}</Typography>
                    <Typography variant='caption'>{slippageAmounts(inputAssetOne, slippage, assetOne)}</Typography>
                  </Box>
                  <Box sx={{ justifyContent: "space-between", display: "flex", }}>
                    <Typography variant='caption'>Min {_get(assetTwo, "symbol", "")}</Typography>
                    <Typography variant='caption'>{slippageAmounts(inputAssetTwo, slippage, assetTwo)}</Typography>
                  </Box>
                  {/* <Box sx={{ justifyContent: 'space-between', display: 'flex', }}>
                        <Typography variant='caption'>Price Impact</Typography>
                        <Typography variant='caption'>{ priceImpact() }%</Typography>
                      </Box> */}
                </Box>
              </Box>
              : null
          }
        </CardContent>
      </Card>
    </Box>

  );
};

export default AddLiquidityPage;
