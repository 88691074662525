// MODALS
export const SET_MODAL = "SET_MODAL";
export const SET_MODAL_DATA = "SET_MODAL_DATA";

// SWAPS
export const INITIALIZE_SWAP = "INITIALIZE_SWAP";
export const SET_TOKEN_SEND = "SET_TOKEN_SEND";
export const SET_TOKEN_RECEIVED = "SET_TOKEN_RECEIVED";
export const SET_SWAPS_TIMING = "SET_SWAPS_TIMING";

//SETTINGS
export const SET_NETWORK = "SET_NETWORK";
export const SET_SLIPPAGE = "SET_SLIPPAGE";
export const SET_DARKMODE = "SET_DARKMODE";
export const SET_USEAPI = "SET_USEAPI";

// WALLET
export const SET_WALLET = "SET_WALLET";
export const SET_PROVIDER = "SET_PROVIDER";
export const SET_SIGNER = "SET_SIGNER";
export const SET_CONNECTED = "SET_CONNECTED";
export const SET_INSTANCE = "SET_INSTANCE";
export const SET_NAME = "SET_NAME";
export const SET_TOKEN_BALANCE = "SET_TOKEN_BALANCE";
export const SET_DISCONNECT_WALLET = "SET_DISCONNECT_WALLET"

// DexData
export const SET_BLOCK_HEIGHT = "SET_BLOCK_HEIGHT";
export const SET_TRADING_VOLUME_DATA = "SET_TRADING_VOLUME_DATA";
export const SET_CAPITAL_LOCKED_DATA = "SET_CAPITAL_LOCKED_DATA";

// Liquidity
export const SET_POSITIONS = "SET_POSITIONS";
export const SET_ASSET_ONE = "SET_ASSET_ONE";
export const SET_ASSET_TWO = "SET_ASSET_TWO";

// Custom Tokens
export const INITIALIZE_TOKENS = "INITIALIZE_TOKENS";
export const SET_CUSTOM_TOKENS = "SET_CUSTOM_TOKENS";

// Positions
export const SET_IMPORT_ASSET_ONE = "SET_IMPORT_ASSET_ONE";
export const SET_IMPORT_ASSET_TWO = "SET_IMPORT_ASSET_TWO";


// BLOCKCHAIN
export const SET_HEAD_INFO = "SET_HEAD_INFO";

