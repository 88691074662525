import { get as _get } from "lodash"
import { Request as JSONRequest } from "../helpers/request";

class GlobalController extends JSONRequest {
  constructor() {
    super({ url: import.meta.env.VITE_BACKEND_API_URL });
  }

  async getBlockHeight() {
    return this.getPrepared("/v2/global")
  }
}


export let global = new GlobalController();